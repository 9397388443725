.div-card-container {
  width: "100%";
  height:400px;
  background-color: #ffffff;
  color: #000000;
  margin: 20px;
  display: grid;
  grid-template-columns: 50% 50%;
  z-index: 9999;
  position: relative;
}

.Section1 {
  flex: 2;
  width: "100%";
  right: 0;
  left: 0;
  height:400px;
}
.Section2 {
  margin-top: 20px;
  margin-right: 10px;
  flex: 2;
  height:400px;
}
.Section3 {
  flex: 1;
  width: "100%";
  right: 0;
  left: 0;
  height:400px;
}
.Section4 {
  margin-top: 20px;
  margin-right: 10px;
  flex: 2;
  height:400px;
}
.popupimage {
  width: "100%";
  height:400px;
}

.popupoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: "rgba(0, 0, 0, 0.5)";
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000000; /* Adjust color as needed */
}
@keyframes animatetitleaboutus {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }

}

.fbpost {
  display: flex;
  justify-content: center;
}

.title-animation-about-us {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  opacity: 0;
  animation: animatetitleaboutus 1.5s ease-in-out forwards;
}

.title-animation-about-us-notice-text {

  color: black;

}


@keyframes animatehraboutus {
  from {
    opacity: 0;
    width: 0%;
  }

  to {
    opacity: 1;
    width: 20%;
  }

}

@keyframes animatehraboutus2 {
  from {
    opacity: 0;
    width: 0%;
  }

  to {
    opacity: 1;
    width: 20%;
  }

}

.animate-hr-tag-about-us {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  opacity: 0;
  border: 1px solid #7cba27;
}

.animate-hr-tag-about-us-white {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  opacity: 0;
  border: 1px solid #ffffff;
}


@keyframes animatehraboutus1 {
  from {
    opacity: 0;
    width: 0%;
  }

  to {
    opacity: 1;
    width: 20%;
  }

}


.animate-hr-tag-about-us1 {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  opacity: 0;
  border: 1px solid #7cba27;
  animation: animatehraboutus1 1.8s ease-in-out forwards;
}

.div-for-hr-animation {
  display: flex;
  width: 600px;
  justify-content: center;
  align-items: center;
}

.home-lower-effect-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: auto;
}

.home-lower-circle-effect {
  width: 500px;
  height: 500px;
  background-color: rgba(208, 244, 111, 0.2);
  border-radius: 100%;
}

.home-lower-circle-effect:nth-child(1) {
  filter: blur(145px);
}

.home-lower-circle-effect:nth-child(2) {
  filter: blur(145px);
}

.home-lower-circle-effect:nth-child(3) {
  filter: blur(145px);
}


@media screen and (max-width: 720px) {
  .fbpost {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .div-for-hr-animation {
    width: -webkit-fill-available;
  }
}



.feature-four_bg_video {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 250px 0;
  margin-right: -102px;
  margin-left: 0px;
  overflow: hidden;
}

.feature-four_bg_video iframe {
 
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 97%;
  object-fit: cover;
  border: none;
}
.pbmit-title22 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: bold;
  color: #FFFFFF;
  font-family: kumbhfonts;
}
.pbmit-heading-desc22 {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  color: #FFFFFF;
  text-align: justify;
  font-family: kumbhfonts;
}

/* .feature-four_bg_video {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 250px 0;
  overflow: hidden;
  margin-right: -102px;
  margin-left: -351.5px;
}

.feature-four_bg_video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw; 
  height: 100vh; 
  object-fit: cover; 
  pointer-events: none; 
} */


/* 


.feature-four_bg_video {
  background-image: url(../../Images_1/AboutUs/AboutCompanyImages/About_1.jpg);
	background-color: transparent;
    background-position: 0% 0%;
    background-repeat: repeat;
    background-size: cover;
	padding: 250px 0;
	height: 100%;
	margin-right: -102px;
	margin-left: -351.5px;
} */